// import GLightbox from "glightbox";

export default {
  init() {
    // JavaScript to be fired on the home page

    // initialiseCountdown();
    // GLightbox();

    initialiseQuoteAnimations();
    initialiseQuestionAnimations();

    // setTimeout(() => {
    initialiseScrollingAnimations();
    // }, 500);

    initialiseFeatureSlider();
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};

function initialiseQuoteAnimations() {
  let quotes = document.querySelectorAll(".quote");

  quotes.forEach((quoteElem, i) => {
    let currQuote = quoteElem;
    let nextQuote = quotes[(i + 1) % quotes.length];

    currQuote.addEventListener("animationend", (e) => {
      switch (e.animationName) {
        case "quote-fade-in":
          currQuote.classList.remove("tn-quote-fade-in");
          currQuote.classList.add("tn-quote-static");
          break;
        case "quote-static":
          currQuote.classList.remove("tn-quote-static");
          currQuote.classList.add("tn-quote-fade-out");
          break;
        case "quote-fade-out":
          currQuote.style.display = "none";
          nextQuote.style.display = "block";
          currQuote.classList.remove("tn-quote-fade-out");
          nextQuote.classList.add("tn-quote-fade-in");
          break;
      }
    });
  });

  quotes[0].classList.add("tn-quote-static");
}

// FIXME: Need to loop on end
function initialiseQuestionAnimations() {
  document.querySelectorAll(".question-slider").forEach((sliderElem) => {
    sliderElem.classList.add("running");

    // const sliderWidth = Array.from(
    //   sliderElem.querySelectorAll(".question")
    // ).reduce((a, b) => a + b.offsetWidth);

    // // if (sliderElem.classList.contains("left")) {
    // //   sliderElem.style.left = "0px";
    // //   sliderElem.style.transition = "all 20s linear";
    // //   sliderElem.style.left = `${sliderWidth}px`;
    // // } else if (sliderElem.classList.contains("right")) {
    // //   sliderElem.style.left = `${sliderWidth}px`;
    // //   sliderElem.style.transition = "all 20s linear";
    // //   sliderElem.style.left = "0px";
    // // }
  });
}

// function initialiseQuestionAnimations() {
//   document.querySelectorAll(".question-fader").forEach((containerElem) => {
//     let quotes = containerElem.querySelectorAll(".question");

//     quotes.forEach((quoteElem, i) => {
//       let currQuote = quoteElem;
//       let nextQuote = quotes[(i + 1) % quotes.length];

//       currQuote.addEventListener("animationend", (e) => {
//         switch (e.animationName) {
//           case "quote-fade-in":
//             currQuote.classList.remove("tn-quote-fade-in");
//             currQuote.classList.add("tn-quote-static");
//             break;
//           case "quote-static":
//             currQuote.classList.remove("tn-quote-static");
//             currQuote.classList.add("tn-quote-fade-out");
//             break;
//           case "quote-fade-out":
//             currQuote.style.display = "none";
//             nextQuote.style.display = "block";
//             currQuote.classList.remove("tn-quote-fade-out");
//             nextQuote.classList.add("tn-quote-fade-in");
//             break;
//         }
//       });
//     });

//     const animationStartDelay = containerElem.dataset.faderStartDelay
//       ? containerElem.dataset.faderStartDelay
//       : 0;

//     setTimeout(() => {
//       quotes[0].classList.add("tn-quote-static");
//     }, animationStartDelay);
//   });
// }

// function initialiseCountdown() {
//   function getTimeRemaining(endtime) {
//     const total = Date.parse(endtime) - Date.parse(new Date());
//     const seconds = Math.floor((total / 1000) % 60);
//     const minutes = Math.floor((total / 1000 / 60) % 60);
//     const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
//     const days = Math.floor(total / (1000 * 60 * 60 * 24));

//     return {
//       total,
//       days,
//       hours,
//       minutes,
//       seconds,
//     };
//   }

//   function initializeClock(clockElem, endtime) {
//     const daysSpan = clockElem.querySelector(".days");
//     const hoursSpan = clockElem.querySelector(".hours");
//     const minutesSpan = clockElem.querySelector(".minutes");
//     const secondsSpan = clockElem.querySelector(".seconds");

//     function updateClock() {
//       const t = getTimeRemaining(endtime);

//       daysSpan.innerHTML = t.days;
//       hoursSpan.innerHTML = ("0" + t.hours).slice(-2);
//       minutesSpan.innerHTML = ("0" + t.minutes).slice(-2);
//       secondsSpan.innerHTML = ("0" + t.seconds).slice(-2);

//       if (t.total <= 0) {
//         clearInterval(timeinterval);
//       }
//     }

//     updateClock();
//     const timeinterval = setInterval(updateClock, 1000);
//   }

//   const clockElem = document.querySelector(".countdown");
//   initializeClock(clockElem, clockElem.dataset.countdownDate);
// }

/**
 * Set up all animations (triggered by scrolling into view)
 */
function initialiseScrollingAnimations() {
  // Safari browsers need a different scrolling mechanism
  if (navigator.vendor.match(/apple/i)) {
    initialiseScrollingAnimationsSafari();
  } else {
    initialiseScrollingAnimationsNonSafari();
  }
}

/**
 * Set up all animations (triggered by scrolling into view) - NON SAFARI
 */
function initialiseScrollingAnimationsNonSafari() {
  // Create the IntersectionObserver callback
  const callback = function (entries, observer) {
    entries.forEach((entry) => {
      // Ensure that the element is really intersecting
      if (entry.isIntersecting) {
        // Get the correct target element (can be more than one)
        const actualTargets = entry.target.dataset.animateTarget
          ? entry.target.querySelectorAll(entry.target.dataset.animateTarget)
          : [entry.target];

        // Add the final animation class to all elements (if there are multiple, they'll be staggered)
        actualTargets.forEach((elem, i) => {
          setTimeout((elem) => elem.classList.add("done"), i * 500, elem);
        });

        // Remove this observer (we don't want it to trigger again)
        observer.unobserve(entry.target);
      }
    });
  };

  // Now create the actual IntersectionObserver (with a small margin to allow element to come into focus)
  const observer = new IntersectionObserver(callback, {
    rootMargin: "-50px 0px",
  });

  // Find all elements that need animating
  const targets = document.querySelectorAll(".tn-animate");

  // Loop through each element
  targets.forEach(function (target) {
    // Check whether the animated element is actually a child element (can be more than one)
    const actualTargets = target.dataset.animateTarget
      ? target.querySelectorAll(target.dataset.animateTarget)
      : [target];

    // Add the animation class to all
    actualTargets.forEach((elem) =>
      elem.classList.add(...target.dataset.animateClass.split(","))
    );

    // Add the element to the watcher
    observer.observe(target);
  });
}

/**
 * Set up all animations (triggered by scrolling into view) - SAFARI
 */
function initialiseScrollingAnimationsSafari() {
  // Find all elements that need animating
  let targets = Array.from(document.querySelectorAll(".tn-animate"));

  // Loop through each element
  targets.forEach(function (target) {
    // Check whether the animated element is actually a child element (can be more than one)
    const actualTargets = target.dataset.animateTarget
      ? target.querySelectorAll(target.dataset.animateTarget)
      : [target];

    // Add the animation class to all
    actualTargets.forEach((elem) =>
      elem.classList.add(...target.dataset.animateClassSafari.split(","))
    );
  });

  // Add the elements to the watcher function
  window.onscroll = function () {
    for (let i = targets.length - 1; i >= 0; i -= 1) {
      const target = targets[i];
      const rect = target.getBoundingClientRect();

      // If the target is currently within a small window of first viewing...
      if (rect.top < -75 && rect.top > -150) {
        // Get the correct target element (can be more than one)
        const actualTargets = target.dataset.animateTarget
          ? target.querySelectorAll(target.dataset.animateTarget)
          : [target];

        // Add the final animation class to all elements (if there are multiple, they'll be staggered)
        actualTargets.forEach((elem, i) => {
          setTimeout((elem) => elem.classList.add("done"), i * 500, elem);
        });

        // Remove this target from the list of targets
        console.log(targets);
        targets.splice(i, 1);

        // If no targets left, stop following the scroll
        if (targets.length == 0) {
          window.onscroll = null;
        }
      }
    }
  };
}

function initialiseFeatureSlider() {
  document
    .querySelectorAll(".feature-slider .slider-button")
    .forEach((button) => {
      button.addEventListener("click", () => {
        // Get the direction from the clicked button
        const direction = button.dataset.direction;

        // Get all slides
        const slides = document.querySelectorAll(".feature-slider .slide");

        // Find the current slide (no "opacity-0" class)
        let currSlideIndex = 0;
        for (let i = 0; i < slides.length; i++) {
          if (!slides[i].classList.contains("lg:opacity-0")) {
            currSlideIndex = i;
            break;
          }
        }

        // Calculate the next slide (based on direction, wrapping at ends)
        let nextSlideIndex = 1;
        if (direction == "left") {
          nextSlideIndex =
            currSlideIndex - 1 < 0 ? slides.length - 1 : currSlideIndex - 1;
        } else {
          nextSlideIndex =
            currSlideIndex + 1 >= slides.length ? 0 : currSlideIndex + 1;
        }

        // Change classes
        slides[currSlideIndex].classList.add("lg:opacity-0", "hidden");
        slides[nextSlideIndex].classList.remove("lg:opacity-0", "hidden");
      });
    });
}
